<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height style="background-color: var(--cor-background-cinza);">
        <v-row justify="center">
          <v-card max-width="400px" outlined class="elevation-5 pa-2 pb-10 rounded-xl">
            <v-card-text>
              <v-row justify="center">
                <v-img :src="imgLogotipo" contain max-height="55px" class="my-15"/>
              </v-row>
              <form v-on:keyup.enter="submit()">
                <v-row justify="center">
                  <v-col cols="8" class="mb-n5">
                    <v-text-field
                        v-model.trim="formulario.usuario"
                        :error-messages="erros.usuario"
                        label="Usuário"
                        placeholder="Usuário"
                        autocomplete="false"
                        class="rounded-lg"
                        solo
                        dense
                        flat
                        outlined
                    />
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                        v-model.trim="formulario.senha"
                        :error-messages="erros.senha"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPass = !showPass"
                        label="Senha"
                        placeholder="Senha"
                        autocomplete="false"
                        :type="!showPass ? 'password' : 'text'"
                        class="rounded-lg"
                        solo
                        dense
                        flat
                        outlined
                    />
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col cols="6">
                    <v-btn
                        color="var(--cor-primaria-100)"
                        dark
                        block
                        @click="submit()"
                        v-text="'Entrar'"
                        class="rounded-xl"
                    />
                  </v-col>
                </v-row>
              </form>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import mixinGeral from "../../mixin/geral";
import helpers from "@/assets/js/helpers";
import {mapActions} from "vuex";

export default {
  name: "Login",
  mixins: [mixinGeral],
  components: {},
  data() {
    return {
      showPass: false,
      formulario: {
        usuario: "",
        senha: "",
      },
      erros: {
        usuario: null,
        senha: null,
      }
    };
  },
  computed: {
    imgLogotipo() {
      return require("../../assets/imgs/logo_retangulo_menor.png");
    },
    iconeErro() {
      return "mdi-alert-octagram";
    },
    habilitacaoSubmit() {
      return !this.loading && !this.notificacao.status;
    },
  },
  methods: {
    ...mapActions(["actionLogin"]),
    async submit() {
      // if (!this.habilitacaoSubmit) return;
      this.ativarLoading();
      try {
        await this.actionLogin({
          login: this.formulario.usuario,
          senha: this.formulario.senha,
        });
        this.navegar("inicio");
      } catch (e) {
        this.interpretarErro(e);
        if (this.obterCodigoRequisicao(e) === 422) {
          const erros = e.response.data.erros;
          if (!helpers.empty(erros.login)) this.erros.usuario = erros.login[0];
          if (!helpers.empty(erros.senha)) this.erros.senha = erros.senha[0];
        }
        this.desativarLoading();
      }
    },
  },
  watch: {},
  created() {
  },
};
</script>